
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AuthenticationService from '@/services/AuthenticationService'

export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const email = 'ensign.johnpaul@gmail.com'
    const password = 'test123!'
    const login = async () => {
      const userEmail = await AuthenticationService.login(email, password)
      await store.dispatch('updateUser', userEmail)
      router.push('/').catch((err) => console.log(err))
    }

    return { store, email, password, login }
  }
}
